<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table>
        <h1>{{ name }}</h1>
      </template>
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <count-stock></count-stock>
      <div class="card">
        <div class="card-header">
          <h4>Management Stock Table</h4>
        </div>
        <div class="card-body">
          <v-data-table
            :headers="headers"
            :items="productStockRequest"
            hide-default-footer
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            class="elevation-1"
          ></v-data-table>
        </div>
        <div class="card-footer text-right">
          <nav class="d-inline-block">
            <ul class="pagination mb-0">
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
              ></v-pagination>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import CountStock from "./CountStock.vue";

export default {
  name: "ManagementStock",
  components: { CountStock },
  data() {
    return {
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      productStockRequest: [],
      name: "Management Stock",
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      axios
        .get(this.$store.state.api + "stock_request", {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((result) => {
          this.loading = false;
          this.productStockRequest = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Request By",
          value: "created_by_name",
        },
        {
          text: "Sender",
          value: "sender_name",
        },
        {
          text: "Receiver",
          value: "receiver_name",
        },
        {
          text: "Note",
          value: "note",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Created At",
          value: "created_at",
        },
      ];
    },
  },
};
</script>
